import {Component, OnInit} from '@angular/core';
import {Storage} from '@ionic/storage-angular';
// import { Storage } from '@capacitor/storage';
import {StorageService} from './services/storage.service';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import commonjs from '@rollup/plugin-commonjs';

import {NavController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AuthService} from './services/auth.service';
import {Router} from '@angular/router';
import {UtilsService} from './services/utils.srvice';
import {Langs} from './languages/langs';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{

  public appPages = [
    {
      title: 'Домашная',
      url: '/home',
      icon: 'home',
      src: ''
    },
    {
      title: 'Экзамены',
      url: '/exams',
      icon: 'document',
      src: ''
    },
    {
      title: 'Контакты',
      url: '/contacts',
      icon: 'people-outline',
      src: ''
    },
    {
      title: 'Настройки',
      url: '/settings',
      icon: 'settings',
      src: ''
    },
    {
      title: 'Пожертвование',
      url: '/donation',
      icon: 'custom-donation',
      src: 'assets/svg/donation.svg'
    },
    {
      title: 'Комната',
      url: '/class-room',
      icon: 'easel',
      // src: 'assets/svg/donation.svg'
    },
    {
      title: 'Landing',
      url: '/landing',
      icon: 'exit',
      src: ''
    },
    {
      title: 'Выход',
      url: '/login',
      icon: 'exit',
      src: ''
    }
  ];

  constructor(
    private storage: Storage,
    private service: StorageService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public auth: AuthService,
    private navCtrl: NavController,
    private lang: Langs,
    private utils: UtilsService,
  ) {
    this.init();
  }

  async ngOnInit() {

  }

  async init() {
    // console.log('>>>>>>>>>>>> this.storage.create()');
    // // await this.storage.defineDriver(CordovaSQLiteDriver);
    // const storage = await this.storage.create();
    // this.service.storage = storage;
    //
    // await storage.set('test-key', 'test-value');
    // const result = await storage.get('test-key');
    // console.log('######### result = ' + result);
    // console.log('>>>>>>>>>>>> this.storage.create() finished');
    // this.service.storage = this;
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.initLocalization();
      this.splashScreen.hide();
    });
  }

  goTo(url) {
    // this.route.navigate([url]);
    this.navCtrl.navigateRoot(url);
  }

  initLocalization() {
    this.appPages[0].title = this.lang.trs('HOME_PAGE.HEADER');
    this.appPages[1].title = this.lang.trs('EXAM_PAGE.HEADER');
    this.appPages[2].title = this.lang.trs('CONTACTS_PAGE.HEADER');
    this.appPages[3].title = this.lang.trs('SETTINGS_PAGE.HEADER');
    this.appPages[4].title = this.lang.trs('DONATION_PAGE.HEADER');
    this.appPages[5].title = this.lang.trs('ROOM_3D_PAGE.HEADER');
    this.appPages[6].title = this.lang.trs('LOGOUT');
  }
  //
  // ionViewDidEnter() {
  //   console.log('>>>>>>>>>>>> ionViewDidEnter');
  // }
}
