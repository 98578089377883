import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { map } from 'rxjs/operators';
// @ts-ignore eslint-disable-next-line no-use-before-define
// import 'rxjs/Rx';
import {environment} from '../../environments/environment';
// import {Storage} from '@ionic/storage';
import {CanActivate, Router} from '@angular/router';
import {NavController} from '@ionic/angular';
import {OfflineMode} from '../decorators/offline-mode.decorator';
import {UtilsService} from './utils.srvice';
import {StorageService} from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService{
  public appToken = 'appToken';
  public authToken = 'authToken';

  public socketStatus = false;
  public socketStatusEvent = new Subject();
  public myName;

  public tokenStatusUpdate = false;

  public storage = null;

  private socket: any;
  private socketSubscriber;

  constructor(
    private http: HttpClient,
    // private storage: Storage,
    private navCtrl: NavController,
    private utils: UtilsService,
    private service: StorageService
  ) {
    console.log('------------------ AuthService constructor ------------------');
    this.storage = this.service.storage;
    this.loadTokenFromStorage();
  }

  // async ngOnInit() {
  //   // await this.storage.create();
  //
  // }

  logout() {
    console.log('You have been logged out!');
    this.socketStatus = false;
    if (this.socketSubscriber) {
      this.socketSubscriber.unsubscribe();
    }
    this.storage.remove('authToken').then(remres => {
    });
    this.navCtrl.navigateRoot('/login');
  }

  public async authSocketMessage(message) {
    console.log('authSocketMessage : ', message);
    if (message && message.token && message.status === 200) {
      this.authToken = message.token;
      this.socketStatus = true;

      if (!this.tokenStatusUpdate) {
        try {
          console.log('storage.set');
          this.storage.set('authToken', this.authToken);
          console.log('_____ token have updated _____');
          this.tokenStatusUpdate = true;
          setTimeout(
            () => (this.tokenStatusUpdate = false),
            Number(this.utils.storedTokenTimeout.split(' ')[0]) * 60 * 1000
          );
        } catch (e) {
          console.log('_____ token have error on store update : ', e);
        }
        // this.storage.set('authToken', this.authToken).then(setres => {
        //
        // }).catch(err => {
        //
        // });
      }

      this.socketStatusEvent.next(true);
    }
  }

  public getAccessToken(): string {
    return this.authToken;
  }

  public getApplicationToken(): string {
    return this.appToken;
  }

  public checkSocket(): boolean {
    // console.log('socketStatus: ', this.socketStatus);
    return this.socketStatus;
  }

  public updateToken(): Observable<any> {
    return Observable.create(observer => {
      if (this.socket) {
        const sub = this.socket
          .subscribe(
            (message) => {
              if (message.token) this.authToken = message.token;
              observer.next(this.authToken);
              sub.unsubscribe();
              observer.complete();
            },
            (err) => {
              observer.error('socket err: ' + err);
              sub.unsubscribe();
              observer.complete();
            },
            () => {
              observer.error('socket closing');
              sub.unsubscribe();
              observer.complete();
            }
          );
        this.sendAuthToken();
      } else {
        observer.error('invalid socket connection');
        observer.complete();
      }
    });
  }

  @OfflineMode("login")
  public login(data) {
    this.initAuthSocket('/auth', data);
    this.socket
      ? (
        this.socketSubscriber = this.socket
          .subscribe(
            (message) => this.authSocketMessage(message),
            (err) => this.showSnakeError(err),
            () => this.showSnakeError('Connection lost')
          )
      )
      : setTimeout(() => {
        console.log('Warning: socket on SocketService is undefined!');
      }, 1);
  }

  emailVarification(hash) {
    return this.http.post(environment.authUri + '/verify', {hash}).pipe(map(value => value));
  }

  public request(key, data) {
    this.socket.next({key, data, token: this.getAccessToken()});
    this.socketSubscriber = this.socket;
    return this.socket;
  }

  public loadTokenFromStorage() {
    this.storage.get('authToken').then(token => {
      console.log("------------- Loaded authToken from storage ------------");
      this.authToken = token;
    });
  }

  public initAuthSocket(path: string, data) {
    let url: any = environment.authUri.split(':');
    // console.log('start url : ', url);
    url[0] = url[0] === 'http' ? 'ws' : 'wss';
    url = url.join(':');

    if (this.authToken === 'authToken')
      // this.appToken = await this.storage.get('authToken')
      this.loadTokenFromStorage()
    let isUsed = false;

    if (!this.isAbsoluteUrl(path)) {
      url = url + path;
    } else {
      url = path + '?EIO=3&transport=websocket&sid=Cej1Kvl65In17l5gAAx1';
      isUsed = true;
    }

    // TODO: sould to change the this.appToken to data.appToken
    if (this.appToken) {
      // console.log("initAuthSocket this.appToken: ", this.appToken);
      url = url + (isUsed ? '&' : '?') + 'Application-Token=' + encodeURIComponent(this.appToken);
      isUsed = true;
    }

    if (data.authToken) {
      url = url + (isUsed ? '&' : '?') + 'Authorization=' + encodeURIComponent(data.tokenValue);
      isUsed = true;
    }

    if (data.name && data.pass) {
      url = url + (isUsed ? '&' : '?') +
        'name=' + encodeURIComponent(data.name) +
        '&pass=' + encodeURIComponent(data.pass);
      this.myName = data.name;
      isUsed = true;
    }

    // console.log('path : ', url);
    // @ts-ignore eslint-disable-next-line no-use-before-define
    this.socket = webSocket(url);
  }

  public sendAuthToken(): void {
    const msg = {
      token: this.authToken
    };
    // console.log('sendAuthToken msg: ', msg);
    this.socket.next(JSON.stringify(msg));
  }

  public onEvent(): Observable<any> {
    return this.socket;
  }

  private isAbsoluteUrl(url: string): boolean {
    const absolutePattern = /^wss?:\/\//i;
    return absolutePattern.test(url);
  }

  private showSnakeError(err): void {
    // console.log('showSnakeError : ', err);
    if (this.socketSubscriber) {
      this.socketSubscriber.unsubscribe();
    }
    this.storage.remove('authToken').then(remres => {
    });
    this.socketStatusEvent.next(false);
    this.logout();
  }
}


@Injectable()
export class AuthGuard implements CanActivate {
  storage = null;
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private navCtrl: NavController,
    // private storage: Storage,
    private service: StorageService
  ) {
    this.storage = this.service.storage;
  }

  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this._authService.socketStatus) {
        return resolve(true);
      }
      this.storage.get('authToken').then(token => {
        this._authService.login({authToken: true, tokenValue: token});

        this._authService.onEvent().subscribe(
          res => {
            return resolve(true);
          },
          err => {
            this.navCtrl.navigateRoot('login');
            return resolve(false);
          },
          () => {
            this.navCtrl.navigateRoot('login');
            return resolve(false);
          }
        );
      });
    });
  }
}
