import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './services/auth.service';
import {FavoritesPageModule} from "./pages/favorites/favorites.module";


const routes: Routes = [
  // {
  //   path: 'home',
  //   loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)},
  {
    path: 'welcome-page',
    loadChildren: () => import('./welcome-page/welcome-page.module').then(m => m.WelcomePagePageModule)
  },
  {
    path: 'verify',
    loadChildren: () => import('./pages/email-verification/email-verification.module').then(m => m.EmailVerificationPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'contacts',
    loadChildren: () => import('./pages/contacts/contacts.module').then(m => m.ContactsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'donation',
    loadChildren: () => import('./pages/donation/donation.module').then(m => m.DonationPageModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'landing',
    loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingPageModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'exams',
    loadChildren: () => import('./pages/exams/exams.module').then(m => m.ExamsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'favorites',
    loadChildren: () => import('./pages/favorites/favorites.module').then(m => m.FavoritesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'exam-share',
    loadChildren: () => import('./pages/exams/exam-share/exam-share.module').then(m => m.ExamSharePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'class-room',
    loadChildren: () => import('./pages/class-room/class-room.module').then(m => m.ClassRoomPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'exam-learning-menu',
    loadChildren: () => import('./pages/exams/exam-learning-menu/exam-learning-menu.module').then(m => m.ExamLearningMenuPageModule),
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
