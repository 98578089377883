import {Injectable} from '@angular/core';
// import {Storage} from '@ionic/storage';
import {StorageService} from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class OfflineService {
  public homePage = true;
  storage = null;

  constructor(private service: StorageService) {
    this.storage = this.service.storage;
  }

  public offlineLogin(userName) {
    return new Promise ((resolce, reject) => {
      if (userName)
        this.storage.get(userName).then(getres => {
          if(getres) return resolce(getres);
          return reject(false);
        });
      else reject(false);
    });
  }

  public offlineLoginInit (userData) {
    if (userData && userData.name)
      this.storage.set(userData.name, userData).then(setres => {
        console.log('user saved !!!', setres);
      });
    else console.log('-----------> inalide user data: ', userData);
  }

  public oflineExamsSet (name) {

  }
}
