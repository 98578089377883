import {NgModule} from '@angular/core';
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AuthGuard, AuthService} from './services/auth.service';

import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {IonicStorageModule, Storage} from '@ionic/storage-angular';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './material.module';
import {LoaderService} from './services/loader.service';
import {FileChooser} from '@ionic-native/file-chooser/ngx';
import {FilePath} from '@ionic-native/file-path/ngx';
import {Base64} from '@ionic-native/base64/ngx';
import {Langs} from './languages/langs';
import {UtilsService} from './services/utils.srvice';
import {CustomPipesModule} from './pipes/custom-pipes.module';
import {OfflineService} from './services/offline.service';
// import Stats from 'three/examples/jsm/libs/stats.module';
import { Drivers } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import {ExamFooterComponentModule} from './components/exam-footer/exam-footer.component.module';
import {ExamHeaderComponentModule} from "./components/exam-header/exam-header.component.module";
// import { Storage } from '@capacitor/storage';


@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MaterialModule,
    CustomPipesModule,
    BrowserAnimationsModule,
    HammerModule,
    ExamFooterComponentModule,
    ExamHeaderComponentModule,
    IonicStorageModule.forRoot({
      name: '__ExaminatorDB',
      // driverOrder: ['sqlite', 'websql', 'indexeddb']
      // driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
    })
  ],
  providers: [
    Storage,
    StatusBar,
    SplashScreen,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    AuthService,
    OfflineService,
    AuthGuard,
    LoaderService,
    UtilsService,
    FileChooser,
    FilePath,
    Base64,
    Langs,
    // Stats
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
