import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'timer'
})
export class CustomTimerPipe implements PipeTransform {

  secondsInMinute = 60;

  constructor() {
  }

  transform(value: any, args?: any): any {
    if (value === 0 || (value && (Number(value)))) {
      let time = Number(value);
      const hours = (time / (this.secondsInMinute ** 2)) | 0;
      time = time % (this.secondsInMinute ** 2);
      const minutes = time / this.secondsInMinute | 0;
      const seconds = time % this.secondsInMinute;
      return (hours ? (hours + ':') : '') + (minutes ? minutes : '00') + ':' + (seconds ? seconds : '00');
    } else {
      return '';
    }
  }

}
