import {NgModule} from '@angular/core';
import {Base64ToImgPipe} from './base64-to-img';
import {CustomTimerPipe} from './custom-timer';
import {TranslatePipe} from './translate';

@NgModule({
  declarations: [
    Base64ToImgPipe,
    CustomTimerPipe,
    TranslatePipe,
  ],
  exports: [
    Base64ToImgPipe,
    CustomTimerPipe,
    TranslatePipe
  ]
})
export class CustomPipesModule {
}
