import {Component, Input, Output, EventEmitter, ElementRef, HostListener, OnInit} from '@angular/core';
import {LoaderService} from '../../services/loader.service';
import {AuthService} from '../../services/auth.service';
import {ModalController, NavController} from '@ionic/angular';
import {environment} from '../../../environments/environment';
import {Langs} from '../../languages/langs';
import {Router} from "@angular/router";
import {UtilsService} from "../../services/utils.srvice";


@Component({
  selector: 'exam-header',
  templateUrl: 'exam-header.component.html',
  styleUrls: ['exam-header.component.scss']
})
export class ExamHeaderComponent implements OnInit {

  @Input() pageName: any;

  public windowWidth = 0;
  public windowHeight = 0;
  public isAuth = false;

  selectedMenu = this.lang.trs('TOOLBAR.EXAMS');

  public onAuthMenu = [
    {
      name: this.lang.trs('TOOLBAR.EXAMS'),
      icon: 'receipt_long',
      action: 'home',
      langKey: 'TOOLBAR.EXAMS'
    },
    {
      name: this.lang.trs('TOOLBAR.FAVORITES'),
      icon: 'bookmark',
      action: 'favorites',
      langKey: 'TOOLBAR.FAVORITES'
    },
    {
      name: this.lang.trs('TOOLBAR.PROFILE'),
      icon: 'person',
      action: 'profile',
      langKey: 'TOOLBAR.PROFILE'
    },
    {
      name: this.lang.trs('TOOLBAR.SETTINGS'),
      icon: 'settings',
      action: 'settings',
      langKey: 'TOOLBAR.SETTINGS'
    },
    {
      name: this.lang.trs('TOOLBAR.SUPPORT'),
      icon: 'Sms',
      action: 'support',
      langKey: 'TOOLBAR.SUPPORT'
    },
  ];

  subForLang;

  constructor(private navCtrl: NavController,
              private auth: AuthService,
              private lang: Langs,
              public utils: UtilsService,
              private router: Router) {
    const menuItem = this.onAuthMenu.find(el => {
      return el.action === this.router.url.split('/')[1] && this.router.url.split('/').length === 2;
    });
    if(menuItem && menuItem.name) {
      this.selectedMenu = menuItem.name;
    }
  }

  ngOnInit() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.isAuth = this.auth.socketStatus;
    this.auth.socketStatusEvent.subscribe((e: boolean) => (this.isAuth = e));
    this.subForLang = this.utils.translateEventOnChange.subscribe((name: string) => {
      this.updateLang();
    });
  }

  updateLang() {
    this.onAuthMenu = this.onAuthMenu.map(el => {
      el.name = this.lang.trs(el.langKey);
      return el;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  }

  goToLogin() {
    this.navCtrl.navigateRoot('login');
  }

  goOut() {
    this.navCtrl.navigateRoot('login');
  }

  goToPage(menu) {
    this.selectedMenu = menu.name;
    this.navCtrl.navigateRoot(menu.action);
  }
}
