import {Injectable} from '@angular/core';
import {RULang} from './langs/ru';
import {ENLang} from './langs/en';
// import {Storage} from '@ionic/storage';
import {UtilsService} from '../services/utils.srvice';
import {StorageService} from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class Langs {

  public name = 'ru';

  public l = {
    ru: new RULang(),
    en: new ENLang(),
  };

  public current: any;
  public invalid = 'INVALID TEXT';

  storage = null;

  constructor(private service: StorageService, public utils: UtilsService) {
    this.storage = this.service.storage;
    this.current = this.l.en;
    this.getLastStoredLang();
  }

  setLang(short, restore = true) {
    this.name = short;
    this.current = this.l[short];
    if (restore) this.storeLang(short);
    this.utils.translateEventOnChange.next(this.name);
  }

  storeLang(lang) {
    this.storage.set('examinatorlang', lang).then(setres => {
      console.log('_____ lang have updated _____');
    }).catch(err => {
      console.log('_____ lang have error on store update : ', err);
    });
  }

  getLastStoredLang() {
    this.storage.get('examinatorlang').then(lang => {
      // console.log('------------- Loaded examinatorlang from storage ', lang, ' ------------');
      lang ? this.setLang(lang, false) : this.setLang('ru');
    });
  }

  public trs(line: string): string {
    if (line) {
      return line.split('.').reduce((base, el) => base[el], this.current.t);
    } else {
      return 'INVALID PARAMS';
    }
  }
}
