import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'toImg'
})
export class Base64ToImgPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: any, args?: any): any {
    if(value) {
      const img = value.split(',');
      img[0] = 'data:image/png;base64';
      value = img.join(',');
      return this.sanitizer.bypassSecurityTrustResourceUrl(value);
    } else  {
      return '';
    }
  }

}
