import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ENLang {

  public t = {
    LOGO: 'EXZ.',
    FULL_LOGO: 'EXZAMINATOR',
    TAGS: 'Create. Learn. Develop',
    FOOTER: {
      SUPPORT: 'Support & Questions',
      SUPPORT_EMAIL: 'exz.source@gmail.com',
      PARTNER: 'Cooperation',
      PARTNER_EMAIL: 'examinator.boss@gmail.com',
    },
    LOGIN_PAGE: {
      TITLE: 'EXAMINATOR',
      DESCRIPTION: 'ONLINE PLATFORM FOR CREATING EXAMS AND TEST SOLVING',
      LOGIN: 'Login/Mail',
      PASSWORD: 'Password',
      FORGOT_PASSWORD: 'Forgot your password?',
      LOGIN_REGISTER_BUTTON: 'Login or Register',
      BUTTONS: {
        LOGIN: 'Login'
      }
    },
    LANDING_PAGE: {
      TITLE: 'EXAMINATOR',
      DESCRIPTION: 'ONLINE PLATFORM FOR CREATING EXAMS AND TEST SOLVING',
      DESCRIPTION_ADS: 'EXZAMENATOR — is an online platform designed to help everyone prepare for exams quickly and easily.' +
        ' To do this, we have created an environment and tools that will help you not only solve the tests available here, ' +
        'but also create your own.',
      LEARN: 'LEARN',
      DEVELOP: 'DEVELOP',
      CREATE: 'CREATE',
      BUTTONS: {
        START: 'Start use',
        SEND_TEST: 'Submit test'
      },
      SIMPLE: 'SIMPLE',
      SIMPLE_DESCRIPTION: 'If you will upload tests and materials to the form, then we will publish them on the platform',
      СOMFORTABLE: 'СOMFORTABLE',
      СOMFORTABLE_DESCRIPTION: 'All tests and solutions are stored in your personal account so that you can look at them or pass them again at any time.',
      ACTUAL: 'ACTUAL',
      ACTUAL_DESCRIPTION: 'We post only relevant exams and tests so that you definitely achieve the best result on time',
      POPULAR_TITLE: 'Most Popular Tests/Exams',
      ADD_TEXT_PDD: 'TRAFFIC RULES',
      ADD_TEXT_EGE: 'Unified State Examination in Informatics',
      ADD_TEXT_TEST: 'PROGRAMMING TEST',
      SCHOOL: 'School',
      COLLEGE: 'Technical College',
      INVITE_TEXT: 'IF YOU HAVE SOMETHING TO SHARE WITH PEOPLE - WHETHER IT\'S A CLASSIC LITERATURE TEST OR LOGIC TEST - TELL US',
      DONATE_TEXT: 'If you like what we do, you can support our project',
      LANDING_DONATE_BUTTON: 'Here',
    },
    HOME_PAGE : {
      HEADER: 'Home',
      TASKS: 'Tasks',
      WELCOME_LABEL: 'Welcome, Exzamenator',
      ALSO: '',
      TICKETS: 'Tickets more',
      POPULAR_PART: {
        HEADER: 'Popular'
      },
      LATEST_CARD: {
        HEADER: 'Latest'
      },
      RECOMMENDED_CARD: {
        HEADER: 'Recommended'
      },
      NOTE: 'NOTE: If you want to start to pass the exam/test, then make double-click on it.',
      NOTE2: 'NOTE 2: To have access to pass an exam/test, you need to add it on your favorites.'
    },
    TOOLBAR: {
      EXAMS: 'Exams',
      HOME: 'Home',
      DONATION: 'Donation',
      FAVORITES: 'Favorites',
      PROFILE: 'Profile',
      SETTINGS: 'Settings',
      SUPPORT: 'Support',
    },
    EXAM_CREATE_PAGE: {
      HEADER: 'Exam creation',
      CATEGORY_LABEL: 'Choose category for new exam',
      CATEGORY_SELECTOR: 'Select Categories',
      EXAM_NAME: 'Exam Name',
      EXAM_DESCRIPTION: 'Exam Description',
      EXAM_IMAGE: 'Exam Image',
      TO_CONTINUE: 'To continue (add sections and questions), you need to fill and save the base information of Exam.',
      EXAM_NOTE: 'You can add tickets and questions to the exam, also you can upload images',
    },
    EXAM_PAGE: {
      HEADER: 'Exams',
      MY_EXAMS: 'My exams',
      TESTS: 'Tests',
      CATEGORIES: {
        SCHOOL: 'Школа'
      },
      EDITOR: {
        HEADER: 'Exam editor',
        NEW_EXAM_NAME: 'New exam name',
        NEW_EXAM_IMG: 'New exam image',
        NEW_EXAM_DESCRIPTION: 'New exam description',
        EXAM_NAME: 'Name of exam: ',
        NEW_TICKET_NAME: 'New ticket name',
        NEW_TICKET_DESCRIPTION: 'New ticket discription',
        QUESTION_HEADER_ADD: 'Menu of question addition:',
        QUESTION_HEADER_EDIT: 'Menu of question editing:',
        QUESTION: 'Question:',
        QUESTION_TYPE: 'Question type:',
        QUESTION_IMG: 'Image of question:',
        ANSWER_TYPE: 'Answer type',
        TYPE_CHECKBOX: 'Can be few right answers',
        TYPE_TEXT: 'Custom text answer',
        TYPE_TOGGLE: 'Only 1 right answer',
        QUESTIONS_PART: 'Part of new question',
        QUESTIONS_LIST: 'List of questions:',
        ANSWERS: 'Answers:',
        ANSWER: 'Answer:',
        ANSWERS_VARIANTS: 'Answers variants:',
        TICKETS_PART: 'New part of tests/exams',
        TICKETS_LIST: 'List of part/tickets',
        NOTES: {
          TICKETS: 'You can select a ticket/section to be able to edit it.',
          SELECTED_TICKET: 'You have selected a ticket/section and now all added questions will belong to the selected ticket/section.',
          QUESTIONS: 'You can select a question to be able to edit it.',
          SELECTED_QUESTION: 'You have selected a question and now you can edit it and change its answer options.'
        }
      },
      TEST: {
        HEADER_PART: 'Test Parts',
        SAME_PART: 'Other similar tests',
        EXAM_INFO: {
          PART: 'parts',
          QUESTIONS: 'questions',
          AUTHOR: 'Author:'
        },
        SELECTED_PAGE: {
          HEADER: 'Type of passing tickets',
          TYPE_OF_LEARNING: 'Type of passing:',
          BY_TIME: 'By time',
          LEARNING: 'Learning',
          TIMEOUT: 'Time Range selection',
          TICKET_SELECTION: 'Ticket selection:',
          RANDOM: 'Random',
          SELECT: 'Select',
          SET_TICKET: 'Set ticket',
        },
        LEARNING: {
          TICKET: 'Ticket:',
          QUESTION: 'Question:',
          TIME: 'Time:',
        },
        RESULT: {
          HEADER: 'Result',
          TICKET_RESULT: 'Result of ticket passing:',
          COUNT_OF_QUESTIONS: 'Count of questions:',
          RIGHT_ANSWERS: 'Right answers:',
          FAILED_ANSWERS: 'Failed answers:',
        }
      }
    },
    SHARED_PAGE: {
      HEADER: 'Share exam',
      USERS_LIST: 'Users list',
    },
    CONTACTS_PAGE: {
      HEADER: 'Contacts',
    },
    DONATION_PAGE: {
      HEADER: 'Donation',
    },
    ROOM_3D_PAGE: {
      HEADER: 'Room',
    },
    EMAIL_VERIFICATION_PAGE: {
      HEADER: 'Email confirmation ',
      MESSAGE: 'Email verification was complete.',
      ERROR: 'Email verification was failed. Your verification token not right or expired.'
    },
    SETTINGS_PAGE: {
      HEADER: 'Settings',
      SELECTED_LANG: 'Selected language:',
      ANIMATIONS: 'Animations:',
    },
    BUTTONS: {
      CREATE_TEST: 'Create TEST',
      MY_EXAMS: 'Мои экзамены',
      ALL_EXAMS: 'Все экзамены',
      CREATE: 'Create',
      SHARE: 'Share',
      PUBLISH: 'Publish',
      HIDE: 'Hide',
      SAVE: 'Save',
      ADD_TICKET: 'Add ticket/group',
      ADD_NEW_ANSWER: 'Add new answer',
      ADD_QUESTION: 'Add question',
      UPDATE_QUESTION: 'update question',
      START: 'Start',
      NEXT: 'Next',
      PREVIOUS: 'Previous',
      FINISH: 'Finish',
      RETRY: 'Retry',
      MENU: 'Menu',
      CONTINUE: 'Continue',
      PASS: 'Start Pass',
      EDIT: 'Edit',
      UPDATE: 'Update',
    },
    MODALS: {
      DELETE: {
        HEADER: 'Attention!',
        MESSAGE: 'Do you wanna <strong>delete</strong> this exam?<br><br>' +
            '<dfn>Note: If you will delete this exam, than all content inside (as tickets and questions) will be also removed.</dfn>',
        YES: 'Yes',
        CANCEL: 'Cancel',
      },
      ADD: {
        HEADER: 'Addition to Favorite',
        MESSAGE: 'Do you wanna <strong>add to favorite</strong> this exam?<br><br>' +
          '<dfn>Note: If you will add this exam to favorite, than you can find it on <strong>Favorite</strong> page.</dfn>',
        YES: 'Yes',
        CANCEL: 'Cancel',
      }
    },
    STATUSES: {
      RIGHT: 'Right',
      FAILED: 'Failed',
    },
    TOAST: {
      WELCOME: 'Authorization complete. Welcome to Examinator.',
      CONFIRM_MAIL: 'You need to confirm your mail.',
      CONNECTION_ERROR: 'Error: Problem with connection to server. Please try later.',
      WRONG_CATEGORY: 'You have not select category.',
      WRONG_EXAM_NAME: 'You have not type the exam title.',
      WRONG_DESCRIPTION: 'You have not type the description.',
      WRONG_TICKET_NAME: 'You have not type the ticket title.',
      WRONG_QUESTION_NAME: 'You have not type the question.',
      WRONG_QUESTION_TYPE: 'You have not select a question type.',
      WRONG_ANSWERS: 'To add the question you need to add more than one answers.',
      WRONG_RIGHT_ANSWERS: 'To add the question you need to set right answer.',
      WRONG_SELECT_TICKET: 'You have not select a ticket.',
      WRONG_SELECT_TIME: 'You have not select a timestamp.',
    },
    LOGOUT: 'Logout',
    ALL_MATERIALS: 'All Materials',
    SEARCH: 'Search',
    FAVORITES: 'Favorites',
    LOGIN: 'Login',
    MENU: 'Menu'
  };

  constructor() {
  }
}
