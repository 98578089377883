export function OfflineMode(type) {

  console.log(' @@@ decorator type: ', type);

  return function (target, props, descriptor) {
    console.log('Our decorated class:', target);
    console.log('Our decorated props:', props);
    console.log('Our decorated descriptor:', descriptor);

    // save a reference to the original method this way we keep the values currently in the
    // descriptor and don't overwrite what another decorator might have done to the descriptor.
    if (descriptor === undefined) {
      descriptor = Object.getOwnPropertyDescriptor(target, props);
    }
    const originalMethod = descriptor.value;

    // editing the descriptor/value parameter
    descriptor.value = function () {
      const args = [arguments[0]];
      // console.log(">>>>>>> args: ", args);
      const result = originalMethod.apply(this, args);
      console.log('Call: ' + props + '(' + args + ') => ' + result);
      return result;
    };

    // return edited descriptor as opposed to overwriting the descriptor
    return descriptor;
  };
}

// const __offline_login = (data) => {
//   this.initAuthSocket('/auth', data);
//   console.log('>>>>>>>> offline auth !!!');
// };

