import {NgModule} from '@angular/core';
import {CustomPipesModule} from '../../pipes/custom-pipes.module';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ExamFooterComponent} from './exam-footer.component';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';


@NgModule({
  declarations: [
    ExamFooterComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CustomPipesModule,

    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
  ],
  exports: [ExamFooterComponent],
  entryComponents: [ExamFooterComponent]
})
export class ExamFooterComponentModule {
}
