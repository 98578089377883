import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {StorageService} from './storage.service';
// import {Storage} from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  public times = [
    {name: '20 мин'},
    {name: '40 мин'},
    {name: '60 мин'},
    {name: '90 мин'},
    {name: '120 мин'},
    {name: '150 мин'},
    {name: '180 мин'}
  ];

  public langs = [
    {name: 'ru'},
    {name: 'en'}
  ];

  public animationFlag = false;

  public tokenStoreTimeout = [
    {interval: '1 мин'},
    {interval: '5 мин'},
    {interval: '10 мин'},
    {interval: '20 мин'},
    {interval: '30 мин'},
  ];
  public storedTokenTimeout = '1 мин';

  public translateEventOnChange = new Subject();
  public animationFlagEventOnChange = new Subject();

  storage = null;

  constructor(private service: StorageService) {
    this.storage = this.service.storage;
    this.getAnimationFlag();
  }

  public storeAnimationFlag(flag) {
    this.animationFlag = (flag === 'true');
    this.animationFlagEventOnChange.next(this.animationFlag);
    this.storage.set('animationFlag', flag).then(setres => {
      console.log('_____ animationFlag have updated _____');
    }).catch(err => {
      console.log('_____ animationFlag have error on store update : ', err);
    });
  }

  public getAnimationFlag() {
    this.storage.get('animationFlag').then(flag => {
      console.log('------------- Loaded animationFlag from storage ', flag, ' ------------');
      this.animationFlag = (flag === 'true');
      this.animationFlagEventOnChange.next(this.animationFlag);
    });
  }

  public storeTokenInterval(interval) {
    this.storage.set('tokenInterval', interval).then(setres => {
      console.log('_____ tokenInterval have updated _____');
    }).catch(err => {
      console.log('_____ tokenInterval have error on store update : ', err);
    });
  }

  public getTokenInterval() {
    this.storage.get('tokenInterval').then(interval => {
      console.log('------------- Loaded tokenInterval from storage ', interval, ' ------------');
      this.storedTokenTimeout = interval;
    });
  }
}
