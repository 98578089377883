import {NgModule} from '@angular/core';
import {CustomPipesModule} from '../../pipes/custom-pipes.module';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ExamHeaderComponent} from './exam-header.component';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';


@NgModule({
  declarations: [
    ExamHeaderComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CustomPipesModule,

    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
  ],
  exports: [ExamHeaderComponent],
  entryComponents: [ExamHeaderComponent]
})
export class ExamHeaderComponentModule {
}
