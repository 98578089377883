import {Component, Input, Output, EventEmitter, ElementRef, HostListener, OnInit} from '@angular/core';
import {LoaderService} from '../../services/loader.service';
import {AuthService} from '../../services/auth.service';
import {ModalController, NavController} from '@ionic/angular';
import {environment} from '../../../environments/environment';


@Component({
  selector: 'exam-footer',
  templateUrl: 'exam-footer.component.html',
  styleUrls: ['exam-footer.component.scss']
})
export class ExamFooterComponent implements OnInit{

  public windowWidth = 0;
  public windowHeight = 0;

  constructor(private navCtrl: NavController) {
  }

  ngOnInit() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  }

  goToPage(name) {
    this.navCtrl.navigateRoot(name);
  }

}
