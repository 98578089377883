import {Pipe, PipeTransform} from '@angular/core';
import {Langs} from '../languages/langs';

@Pipe({
  name: 'trs',
  pure: false
})
export class TranslatePipe implements PipeTransform {

  constructor(private langs: Langs) {
  }

  transform(value: any, args?: any): any {
    if (typeof value === 'string') {
      return value.split('.').reduce((base, el) => base[el], this.langs.current.t);
    } else {
      return this.langs.invalid;
    }
  }

}
