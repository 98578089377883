import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RULang {

  public t = {
    LOGO: 'EXZ.',
    FULL_LOGO: 'EXZAMINATOR',
    TAGS: 'Создавайте. Обучайтесь. Развивайтесь',
    FOOTER: {
      SUPPORT: 'Поддержка и вопросы',
      SUPPORT_EMAIL: 'exz.source@gmail.com',
      PARTNER: 'Сотрудничество',
      PARTNER_EMAIL: 'examinator.boss@gmail.com',
    },
    LOGIN_PAGE: {
      TITLE: 'EXAMINATOR',
      DESCRIPTION: 'ОНЛАЙН-ПЛАТФОРМА ДЛЯ СОЗДАНИЯ ЭКЗАМЕНОВ И РЕШЕНИЯ ТЕСТОВ',
      LOGIN: 'Логин/Мэил',
      PASSWORD: 'Пароль',
      FORGOT_PASSWORD: 'Забыли пароль?',
      LOGIN_REGISTER_BUTTON: 'Логин или Регистрация',
      BUTTONS: {
        LOGIN: 'Войти'
      }
    },
    LANDING_PAGE: {
      TITLE: 'EXAMINATOR',
      DESCRIPTION: 'ОНЛАЙН-ПЛАТФОРМА ДЛЯ СОЗДАНИЯ ЭКЗАМЕНОВ И РЕШЕНИЯ ТЕСТОВ',
      DESCRIPTION_ADS: 'EXZAMENATOR — это онлайн-платформа, созданная для того, чтобы каждый человек смог подготовиться к' +
        ' экзаменам быстро и легко. Для этого мы создали среду и инструменты, которые помогут не' +
        ' только решать доступные здесь тесты, но и создавать свои.',
      LEARN: 'ОБУЧАЙТЕСЬ',
      DEVELOP: 'РАЗВИВАЙТЕСЬ',
      CREATE: 'СОЗДАВАЙТЕ',
      BUTTONS: {
        START: 'Начать пользоваться',
        SEND_TEST: 'Отправить тест'
      },
      SIMPLE: 'ПРОСТО',
      SIMPLE_DESCRIPTION: 'Вы загружаете тесты и материалы в форму, мы публикуем их на платформе',
      СOMFORTABLE: 'УДОБНО',
      СOMFORTABLE_DESCRIPTION: 'Все тесты и решения хранятся в личном кабинете, чтобы вы в любой момент могли их посмотреть или пройти снова',
      ACTUAL: 'АКТУАЛЬНО',
      ACTUAL_DESCRIPTION: 'Мы выкладываем только актуальные экзамены и тесты, чтобы вы точно добились лучшего результата в срок',
      POPULAR_TITLE: 'Самые популярные тесты',
      ADD_TEXT_PDD: 'ПРАВИЛА ДОРОЖНОГО ДВИЖЕНИЯ',
      ADD_TEXT_EGE: 'ЕГЭ ПО ИНФОРМАТИКЕ',
      ADD_TEXT_TEST: 'ТЕСТ ПО ПРОГРАММИРОВАНИЮ',
      SCHOOL: 'Школа',
      COLLEGE: 'Техникум',
      INVITE_TEXT: 'ЕСЛИ У ВАС ЕСТЬ, ЧЕМ ПОДЕЛИТЬСЯ С ЛЮДЬМИ — БУДЬ ТО КЛАССНЫЙ ТЕСТ ПО ЛИТЕРАТУРЕ ИЛИ ЗАДАЧКИ НА ЛОГИКУ — РАССКАЖИТЕ НАМ',
      DONATE_TEXT: 'Если вам нравится то, что мы делаем поддержите наш проект',
      LANDING_DONATE_BUTTON: 'Вот здесь',
    },
    HOME_PAGE : {
      TASKS: 'Задач',
      HEADER: 'Домашняя',
      WELCOME_LABEL: 'Привет, Exzamenator',
      ALSO: 'Ещё',
      TICKETS: 'Билетов',
      POPULAR_PART: {
        HEADER: 'Популярные'
      },
      LATEST_CARD: {
        HEADER: 'Последние'
      },
      RECOMMENDED_CARD: {
        HEADER: 'Рекомендуемые'
      },
      NOTE: 'ПРИМЕЧАНИЕ: Если вы хотить начать проходить экзамен/тест, то нажмите на него дважды.',
      NOTE2: 'ПРИМЕЧАНИЕ 2: Что бы получить доступ к экзамену/тесту, необходимо добить его в избранные.'
    },
    TOOLBAR: {
      EXAMS: 'Экзамены',
      HOME: 'Домашняя',
      DONATION: 'Пожертвования',
      FAVORITES: 'Избранное',
      PROFILE: 'Профиль',
      SETTINGS: 'Настройки',
      SUPPORT: 'Помощь',
    },
    EXAM_CREATE_PAGE: {
      HEADER: 'Создание экзамена',
      CATEGORY_LABEL: 'Выбор категории для нового экзамена',
      CATEGORY_SELECTOR: 'Выбрать Категорию',
      EXAM_NAME: 'Имя Экзамена',
      EXAM_DESCRIPTION: 'Описание Экзамена',
      EXAM_IMAGE: 'Изображение Экзамена',
      TO_CONTINUE: 'Для того что бы продолжыть (добавить разделы и вопросы), необходимо заполнить и сохранить основную информацию об Экзамене',
      EXAM_NOTE: 'Вы можете добавить разделы и вопросы к экзамену, а так же загрузить изображения',
    },
    EXAM_PAGE: {
      HEADER: 'Экзамены',
      MY_EXAMS: 'Мои экзамены',
      TESTS: 'Тестов',
      CATEGORIES: {
        SCHOOL: 'Школа'
      },
      EDITOR: {
        HEADER: 'Редактирование экзамена',
        NEW_EXAM_NAME: 'Новое название экзамена',
        NEW_EXAM_IMG: 'Новое изображение экзамена',
        NEW_EXAM_DESCRIPTION: 'Новое описание экзамена',
        EXAM_NAME: 'Название экзамена: ',
        NEW_TICKET_NAME: 'Название нового билета',
        NEW_TICKET_DESCRIPTION: 'Описание нового билета',
        QUESTION_HEADER_ADD: 'Меню добавления вопроса:',
        QUESTION_HEADER_EDIT: 'Меню редактирования вопроса:',
        QUESTION: 'Вопрос:',
        QUESTION_TYPE: 'Тип вопроса:',
        QUESTION_IMG: 'Картинка для вопроса:',
        ANSWER_TYPE: 'Тип ответа',
        TYPE_CHECKBOX: 'Могут быть несколько верных ответов',
        TYPE_TEXT: 'Свободный текстовый ответ',
        TYPE_TOGGLE: 'Только 1 верный варинат',
        QUESTIONS_PART: 'Новый вопрос',
        QUESTIONS_LIST: 'Список вопросов:',
        ANSWERS: 'Ответы:',
        ANSWER: 'Ответ:',
        ANSWERS_VARIANTS: 'Варианты ответов:',
        TICKETS_PART: 'Новый раздел теста/экзамена',
        TICKETS_LIST: 'Список разделов/билетов',
        NOTES: {
          TICKETS: 'Необходимо выбрать билет/раздел, что бы иметь возможность его отредактировать.',
          SELECTED_TICKET: 'Вы выбрали билет/раздел и теперь все добавленные вопросы будут пренадлежать выбранному билету/разделу',
          QUESTIONS: 'Необходимо выбрать вопрос, что бы иметь возможность его отредактировать.',
          SELECTED_QUESTION: 'Вы выбрали вопрос и теперь вы можете его отредактировать и его изменить варианты его ответов.'
        }
      },
      TEST: {
        HEADER_PART: 'Разделы теста',
        SAME_PART: 'Ещё похожие тесты',
        EXAM_INFO: {
          PART: 'части(ей)',
          QUESTIONS: 'вопроса(ов)',
          AUTHOR: 'Автор:'
        },
        SELECTED_PAGE: {
          HEADER: 'Выбор спосаба прохождения билетов',
          TYPE_OF_LEARNING: 'Тип прохождения билета:',
          BY_TIME: 'На время',
          LEARNING: 'Изучение',
          TIMEOUT: 'Выбрать временной промежуток',
          TICKET_SELECTION: 'Выбор билета:',
          RANDOM: 'Случайно',
          SELECT: 'Выбрать',
          SET_TICKET: 'Установить билет',
        },
        LEARNING: {
          TICKET: 'Билет:',
          QUESTION: 'Вопрос:',
          TIME: 'Время:',
        },
        RESULT: {
          HEADER: 'Результат',
          TICKET_RESULT: 'Результат прохождения бидета:',
          COUNT_OF_QUESTIONS: 'Общее количество вопросов:',
          RIGHT_ANSWERS: 'Верных ответов:',
          FAILED_ANSWERS: 'Невереых ответов:',
        }
      }
    },
    SHARED_PAGE: {
      USERS_LIST: 'Список пользователей',
      HEADER: 'Поделиться экзаменом',
    },
    CONTACTS_PAGE: {
      HEADER: 'Контакты',
    },
    DONATION_PAGE: {
      HEADER: 'Пожертвование',
    },
    ROOM_3D_PAGE: {
      HEADER: 'Комната',
    },
    EMAIL_VERIFICATION_PAGE: {
      HEADER: 'Подтверждение Email',
      MESSAGE: 'Проверка почты завершена.',
      ERROR: 'Ошибка подтверждения почты. Ваш токен подтверждения не верен или его действие истекло.'
    },
    SETTINGS_PAGE: {
      HEADER: 'Настройки',
      SELECTED_LANG: 'Выбранный язык:',
      ANIMATIONS: 'Анимации:',
    },
    BUTTONS: {
      CREATE_TEST: 'Создать ТЕСТ',
      MY_EXAMS: 'Мои экзамены',
      ALL_EXAMS: 'Все экзамены',
      CREATE: 'Создать',
      SHARE: 'Поделиться',
      PUBLISH: 'Опубликовать',
      HIDE: 'Скрыть',
      SAVE: 'Сохранить',
      ADD_TICKET: 'Добавить билет/группу',
      ADD_NEW_ANSWER: 'добавить вариант ответа',
      ADD_QUESTION: 'Добавить вопрос',
      UPDATE_QUESTION: 'Редактировать вопрос',
      START: 'Начать',
      NEXT: 'Следующий',
      PREVIOUS: 'Предыдущий',
      FINISH: 'Завершить',
      RETRY: 'Повторить',
      MENU: 'Меню',
      CONTINUE: 'Продолжить',
      PASS: 'Начать Прохождение',
      EDIT: 'Редактировать',
      UPDATE: 'Обновить',
    },
    MODALS: {
      DELETE: {
        HEADER: 'Подтверждение!',
        MESSAGE: 'Вы уверены что хотите <strong>удалить</strong> этот экзамен?<br><br>' +
            '<dfn>Предупреждение: Если вы удалите экзамен, то все билеты и вопросы в нём так же будут удалены.</dfn>',
        YES: 'Да',
        CANCEL: 'Закрыть',
      },
      ADD: {
        HEADER: 'Добавление в Избранное',
        MESSAGE: 'Хотите ли вы <strong>добавить в Избранное</strong> этот тест?<br><br>' +
          '<dfn>Заметка: Если вы добавите этот экзамен в Избранном, вы сможете найти его на странице <strong>Избранное</strong></dfn>',
        YES: 'Да',
        CANCEL: 'Отмена',
      }
    },
    STATUSES: {
      RIGHT: 'Верно',
      FAILED: 'Не верно',
    },
    TOAST: {
      WELCOME: 'Вы были авторизованы. Добро пожаловать Examinator.',
      CONFIRM_MAIL: 'Вам необходимо подтвердить свою почту для продолжения.',
      CONNECTION_ERROR: 'Ошибка: Возникла проблема с подключением к серверу. Пожалуйста повторите попытку позже.',
      WRONG_CATEGORY: 'Вы не выбрали категорию.',
      WRONG_EXAM_NAME: 'Вы не ввели название экзамена.',
      WRONG_DESCRIPTION: 'Вы не добавили описание.',
      WRONG_TICKET_NAME: 'Вы не ввели название билета.',
      WRONG_QUESTION_NAME: 'Вы не ввели вопрос.',
      WRONG_QUESTION_TYPE: 'Вы не выбрали тип вопроса.',
      WRONG_ANSWERS: 'Для добавление вопроса вам необходимо добавить более одного варианта ответов.',
      WRONG_RIGHT_ANSWERS: 'Для добавление вопроса необходимо выбрать правельный вариант ответа.',
      WRONG_SELECT_TICKET: 'Не выбран билет.',
      WRONG_SELECT_TIME: 'Не выбран временной промежуток.',
    },
    LOGOUT: 'Выход',
    ALL_MATERIALS: 'Все Материалы',
    SEARCH: 'Поиск',
    FAVORITES: 'Избранное',
    LOGIN: 'Войти',
    MENU: 'Меню'
  };

  constructor() {
  }
}
