import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public loader: any;

  constructor(public loadingController: LoadingController) {
  }

  public start() {
    // console.log('???????????? loader start ????????????: ', this.loader);
    return new Promise(async resolve => {
      // console.log('>>>>>>> this.loader in promise: ', this.loader);
      if (!this.loader) {
        this.loader = await this.loadingController.create({message: 'Please wait...'});
        await this.loader.present();
      }
      resolve(true);
    });
  }

  // TODO: need to fix, dismiss() does not work
  public async stop() {
    // console.log('???????????? loader stop ????????????: ', this.loader);
    if (this.loader) {
      await this.loadingController.dismiss();
      // await this.loader.stop();
      this.loader = undefined;
    }

    const loader = await this.loadingController.getTop();
    // if loader present then dismiss
    if(loader !== undefined) {
      await this.loadingController.dismiss();
    }
  }
}
